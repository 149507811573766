<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="caoZuoYuan" label="操作员" />
            <x-detail-item prop="shiJian" label="时间" />
            <x-detail-item prop="shouJiHaoQian" label="手机号前" />
            <x-detail-item prop="shouJiHaoHou" label="手机号后" />
            <x-detail-item prop="jinEQian" label="金额前" />
            <x-detail-item prop="jinEHou" label="金额后" />
            <x-detail-item prop="dengJiQian" label="等级前" />
            <x-detail-item prop="dengJiHou" label="等级后" />
            <x-detail-item prop="jiFenQian" label="积分前" />
            <x-detail-item prop="jiFenHou" label="积分后" />
            <x-detail-item prop="xiuGaiMiMa" label="修改密码" />
            <x-detail-item prop="xingMing" label="姓名" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/BianGengJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                kaHao: "", // 卡号
                caoZuoYuan: "", // 操作员
                shiJian: "", // 时间
                shouJiHaoQian: "", // 手机号前
                shouJiHaoHou: "", // 手机号后
                jinEQian: "", // 金额前
                jinEHou: "", // 金额后
                dengJiQian: "", // 等级前
                dengJiHou: "", // 等级后
                jiFenQian: "", // 积分前
                jiFenHou: "", // 积分后
                xiuGaiMiMa: "", // 修改密码
                xingMing: "", // 姓名
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>