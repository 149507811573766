<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="卡号" >
                <el-input v-model="search.kaHao" size="small" />
            </x-search-item>
            <x-search-item label="时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.shiJianBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.shiJianEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="手机号" >
                <el-input v-model="search.shouJiHaoQian" size="small" />
            </x-search-item>
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
<!--        <div class="x-page-btn">-->
<!--            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
<!--        </div>-->
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange" >

<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column label="会员"  align="center">
                <el-table-column width="180" prop="kaHao" label="卡号" />
                <el-table-column width="180" prop="xingMing" label="姓名" />
            </el-table-column>
            <el-table-column label="操作" align="center">
                <el-table-column width="180" prop="caoZuoYuan" label="操作员" />
                <el-table-column width="180" prop="shiJian" label="时间" />
            </el-table-column>
            <el-table-column label="手机号" align="center">
                <el-table-column width="180" prop="shouJiHaoQian" label="修改前" />
                <el-table-column width="180" prop="shouJiHaoHou" label="修改后" />
            </el-table-column>

            <el-table-column label="金额" align="center">
                <el-table-column width="180" prop="jinEQian" label="修改前" />
                <el-table-column width="180" prop="jinEHou" label="修改后" />
            </el-table-column>

            <el-table-column label="等级" align="center">
                <el-table-column width="180" prop="dengJiQian" label="修改前" />
                <el-table-column width="180" prop="dengJiHou" label="修改后" />
            </el-table-column>

            <el-table-column label="积分" align="center">
                <el-table-column width="180" prop="jiFenQian" label="修改前" />
                <el-table-column width="180" prop="jiFenHou" label="修改后" />
            </el-table-column>
            <el-table-column width="180" prop="xiuGaiMiMa" label="修改密码" />
<!--            <el-table-column width="250" align="center" label="操作" fixed="right">-->
<!--                <template slot-scope="{ row, $index }">-->
<!--                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>-->
<!--&lt;!&ndash;                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>&ndash;&gt;-->
<!--                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">-->
<!--                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>-->
<!--                    </el-popconfirm>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/BianGengJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/bgjl/BianGengJiLuEdit";
    import Detail from "@/view/hygl/bgjl/BianGengJiLuDetail";

    export default {
        name: "BianGengJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    kaHao: '',
                    shiJianBegin: '',
                    shiJianEnd: '',
                    shouJiHaoQian: '',
                    xingMing: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
