<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="卡号" prop="kaHao" >
                <el-input v-model="form.kaHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="操作员" prop="caoZuoYuan" >
                <el-input v-model="form.caoZuoYuan" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="时间" prop="shiJian">
                <el-date-picker v-model="form.shiJian" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="手机号前" prop="shouJiHaoQian" >
                <el-input v-model="form.shouJiHaoQian" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="手机号后" prop="shouJiHaoHou" >
                <el-input v-model="form.shouJiHaoHou" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="金额前" prop="jinEQian" >
                <el-input v-model="form.jinEQian" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="金额后" prop="jinEHou" >
                <el-input v-model="form.jinEHou" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="等级前" prop="dengJiQian" >
                <el-input v-model="form.dengJiQian" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="等级后" prop="dengJiHou" >
                <el-input v-model="form.dengJiHou" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="积分前" prop="jiFenQian" >
                <el-input v-model="form.jiFenQian" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="积分后" prop="jiFenHou" >
                <el-input v-model="form.jiFenHou" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="修改密码" prop="xiuGaiMiMa" >
                <el-input v-model="form.xiuGaiMiMa" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" >
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/BianGengJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                kaHao: [], // 卡号
                caoZuoYuan: [], // 操作员
                shiJian: [], // 时间
                shouJiHaoQian: [], // 手机号前
                shouJiHaoHou: [], // 手机号后
                jinEQian: [], // 金额前
                jinEHou: [], // 金额后
                dengJiQian: [], // 等级前
                dengJiHou: [], // 等级后
                jiFenQian: [], // 积分前
                jiFenHou: [], // 积分后
                xiuGaiMiMa: [], // 修改密码
                xingMing: [], // 姓名
            }
            this.titlePrefix = '变更记录';
            this.defaultForm = {
                id: null,
                kaHao: "", // 卡号
                caoZuoYuan: "", // 操作员
                shiJian: "", // 时间
                shouJiHaoQian: "", // 手机号前
                shouJiHaoHou: "", // 手机号后
                jinEQian: "", // 金额前
                jinEHou: "", // 金额后
                dengJiQian: "", // 等级前
                dengJiHou: "", // 等级后
                jiFenQian: "", // 积分前
                jiFenHou: "", // 积分后
                xiuGaiMiMa: "", // 修改密码
                xingMing: "", // 姓名
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>